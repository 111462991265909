import React from "react";
import {useTermsAndPrivacyContent} from "App/Game/hooks";
import Hero from "./components/Hero";
import About from "./components/About";
import Features from "./components/Features";
import Advantages from "./components/Advantages";
import Tutorial from "./components/Tutorial";
import Footer from "./components/Footer";
import Modal from "App/React/_UI/Modal/Modal";
import './styles.scss';

const Landing = ({onBtnClick, gameGA, ga}) => {
    const [modalContent, isModalOpen, showTermsUse, hideTermsUse] = useTermsAndPrivacyContent();

    return (
        <div className={"landing-phase"}>
            <main>
                <Hero onBtnClick={onBtnClick} gameGA={gameGA} ga={ga}/>
                <About onBtnClick={onBtnClick}/>
                <Features/>
                <Advantages onBtnClick={onBtnClick}/>
                <Tutorial onBtnClick={onBtnClick}/>
            </main>
            <Footer showTermsUse={showTermsUse}/>
            {isModalOpen &&
                <Modal
                    title={modalContent ? modalContent.title : ''}
                    content={modalContent ? modalContent.content : ''}
                    onClose={hideTermsUse}
                />
            }
        </div>
    )
}

export default Landing;