import React from 'react';
import LazyLoad from 'react-lazy-load';

const LazyImg = ({url, offset = 100, height, width, ...props}) => {

    return (
        <LazyLoad  offset={offset} >
            <img src={url} width={width} height={height} {...props} />
        </LazyLoad>
    );
};

export default LazyImg;
