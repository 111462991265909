import React, { useRef } from "react";
import {
    useLevelLoaded,
    useLevelStarted,
    useResultHide,
    useResultShow,
    usePauseHide,
    usePauseShow,
    useCustomisationClose,
    useCustomisationOpen,
} from "../Game/hooks";

const VliBanners = ({ setStartPreroll, addEventListener, removeEventListener }) => {
    const refHpBannerCenter = useRef(null);
    const refHpBannerBottom = useRef(null);
    const refGameplayBannerBottom = useRef(null);
    const refPauseBannerCenter = useRef(null);
    const refPauseBannerBottom = useRef(null);
    const refRestartBannerCenter = useRef(null);
    const refRestartBannerBottom = useRef(null);

    useLevelLoaded(refHpBannerCenter, refHpBannerBottom, addEventListener, removeEventListener);
    useLevelStarted(refHpBannerCenter, refHpBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    useResultShow(refRestartBannerCenter, refRestartBannerBottom, refGameplayBannerBottom, setStartPreroll, addEventListener, removeEventListener);
    useResultHide(refRestartBannerCenter, refRestartBannerBottom, addEventListener, removeEventListener);
    usePauseShow(refPauseBannerCenter, refPauseBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    usePauseHide(refPauseBannerCenter, refPauseBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    useCustomisationOpen(refHpBannerCenter, refRestartBannerCenter, addEventListener, removeEventListener);
    useCustomisationClose(refHpBannerCenter, refRestartBannerCenter, addEventListener, removeEventListener);

    return (
        <>
            {/* vli ad center banners */}
            <div
                ref={refHpBannerCenter}
                data-ga-id="snakecolorbrake_vli_300x250_hp"
                className="center-banner">
                <div data-ad-slot="pw_17220" className="adsbyvli" />
            </div>
            <div
                ref={refRestartBannerCenter}
                data-ga-id="snakecolorbrake_vli_300x250_restart"
                className="center-banner">
                <div data-ad-slot="pw_17871" className="adsbyvli"/>
            </div>
            <div
                ref={refPauseBannerCenter}
                data-ga-id="snakecolorbrake_vli_300x250_pause"
                className="center-banner">
                <div data-ad-slot="pw_17869" className="adsbyvli" />
            </div>
            {/* vli ad bottom banners */}
            <div
                data-ga-id="snakecolorbrake_vli_320x50_hp"
                ref={refHpBannerBottom}
                className="footer-banner-hp">
                <div data-ad-slot="pw_17216" className="adsbyvli" />
            </div>
            <div
                data-ga-id="snakecolorbrake_vli_320x50_gameplay"
                ref={refGameplayBannerBottom}
                className="footer-banner-gameplay">
                <div data-ad-slot="pw_17868" className="adsbyvli" />
            </div>
            <div
                data-ga-id="snakecolorbrake_vli_320x50_restart"
                ref={refRestartBannerBottom}
                className="footer-banner-restart">
                <div data-ad-slot="pw_17872" className="adsbyvli" />
            </div>
            <div
                data-ga-id="snakecolorbrake_vli_320x50_pause"
                ref={refPauseBannerBottom}
                className="footer-banner-pause">
                <div data-ad-slot="pw_17870" className="adsbyvli" />
            </div>
        </>
    )
};

export default VliBanners;