import React from 'react';
import * as Sentry from "@sentry/browser";
import {deviceDetect, browserName, fullBrowserVersion} from 'react-device-detect';
import UrlParams from "App/Helpers/UrlParams";
import SharpstarServiceHandler from "App/Services/Handlers/SharpstarServiceHandler";
import config, {gameAnalyticsConfig} from './config';
import CookieService from "App/Services/CookieService";
import WebsiteDataService from "App/Services/WebsiteDataService";
import SentryConfig from "App/Helpers/SentryConfig";
import {environment} from "App/Helpers/enviroments";
import GoogleAnalytics from "App/Services/Analytics/GA4";
import GameAnalytics from "App/Services/Analytics/GameAnalytics";
import checkAdBlockers from "App/Helpers/checkAdBlockers";
import {consoleInfoLog} from "App/Helpers/consoleInfoLog";
import {getGameDefaults} from "./helpers";
import Landing from "./components/Landing";
import GameContainer from "./components/Game/components/GameContainer";
import PushPhase from "./components/PushPhase";
import AdBlockerModal from "./components/AdBlockerModal";
import "./main.scss";

const phases = ['landing', 'push', 'game'];

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phase: '',
            offerId: null,
            adBlocker: false,
            showAdBlockerModal: false,
            pushViews: 0,
            gameGA: null, // Game Analytics
            ga: null, // Google Analytics
            adProvider: null,
            gameConfig: {
                buildVersion: null,
                buildUrl: null,
                forcedReload: null,
            },
            preroll: {
                showInterval: 1,
                showStartAttempt: 2,
            },
            pushPrompt: {
                total: 0,
            },
        };

        this.setUrlParam = this.setUrlParam.bind(this);
        this.setPhase = this.setPhase.bind(this);
        this.updateState = this.updateState.bind(this);
        this.playBtnClickHandler = this.playBtnClickHandler.bind(this);
        this.resolveAdsBlocked = this.resolveAdsBlocked.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);

        this.setUrlParamValues(); // Set refids, hash, rid
        this.initServices(); // Init SharpStar, Sentry, WebsiteDataService
    }

    setUrlParam(name) {
        if (this[name] !== '0' && this[name] !== null && this[name] !== undefined) { // If exists save it to local storage.
            localStorage.setItem(name, this[name]);
        } else if (localStorage.getItem(name)) {  // Else get from local storage and set
            this[name] = localStorage.getItem(name);
        }
    }

    setUrlParamValues() {
        this.refid3 = UrlParams.refid3;
        this.refid2 = UrlParams.refid2;
        this.refid1 = UrlParams.refid1;
        this.hash = UrlParams.hash;
        this.rid = UrlParams.rid || config.rid;

        this.setUrlParam('refid3');
        this.setUrlParam('refid2');
        this.setUrlParam('refid1');
        this.setUrlParam('hash');
        this.setUrlParam('rid');
    }

    updateState(value) {
        this.setState(value);
    }

    initServices() {
        this.sharpstarDataService = new SharpstarServiceHandler(
            config.sharpstarApiUrl,
            config.source,
            config.campaignId,
            new CookieService(config.domain)
        );

        this.websiteDataService = new WebsiteDataService(config.modalsUrl, config.lang);

        SentryConfig.init(
            config.sentryEnabled,
            null,
            config.source,
            config.campaignId,
            null,
            document.location.host,
            environment(),
            this.refid3
        );
    }

    getPhase() {
        return localStorage.getItem('phase') || 'landing';
    }

    setPhase(name) {
        this.setState({phase: name});
        localStorage.setItem('phase', name);
    }

    checkPhase() {
        const phase = this.getPhase();

        phase !== 'game' ? this.setPhase(phase) : this.setState({phase: 'game'});
    }

    getPushViews() {
        return parseInt(localStorage.getItem('pushViews') || 0);
    }

    setPushViews(views) {
        this.setState({pushViews: views});
        localStorage.setItem('pushViews', views);
    }

    checkPush() {
        const {phase, gameGA, ga, adBlocker, pushPrompt} = this.state;

        if (!("Notification" in window)) {
            consoleInfoLog('Web Push not supported', '#f3aa4e', '#094205');

            phase === 'landing' && this.setPhase('game');

            gameGA.sendDesignEvent('Push:NotAvailable');
            ga && ga.basicGaEvent('push', 'not_available');
        } else {
            if (!localStorage.getItem('push-allowed') && this.getPushViews() < pushPrompt.total) {
                !adBlocker && this.setPushViews((this.getPushViews() + 1));
                this.getPhase() !== 'push' && this.setPhase('push');
            } else {
                this.setPhase('game');
            }
        }
    }

    playBtnClickHandler() {
        const {adBlocker, gameGA, ga} = this.state;

        if (adBlocker) {
            this.setState({showAdBlockerModal: true});
        } else {
            gameGA &&  gameGA.sendDesignEvent('HP:PlayButton');
            ga && ga.gaEvent('play_button');
        }

        this.checkPush();
    }

    resolveAdsBlocked(adsBlocked) {
        const {phase, gameConfig} = this.state;

        if (adsBlocked) {
            phase !== 'landing' && this.setState({showAdBlockerModal: true});
            this.setState({adBlocker: adsBlocked});
        } else {
            this.setState({
                ga: new GoogleAnalytics(
                    true,
                    config.source,
                    config.gaTrackId,
                    {},
                    {'debug_mode': environment() !== 'production'}
                )
            })

            this.setState({
                gameGA: new GameAnalytics(
                    gameAnalyticsConfig.gameKey,
                    gameAnalyticsConfig.gameSecret,
                    environment(),
                    gameConfig.buildVersion
                )
            })

            this.getDeviceInfo();
        }

        phase !== 'landing' && this.checkPush();
    }

    handleBeforeUnload = () => {
        const {phase, gameGA, ga} = this.state;

        gameGA && gameGA.sendDesignEvent('Page_Unload_Phase', phases.indexOf(phase));
        ga && ga.gaEvent('page_unload', {phase});
    }

    getDeviceInfo = () => {
        const {gameGA, ga} = this.state;
        const deviceInfo = deviceDetect(undefined);
        const deviceType = deviceInfo.vendor ? 'Mobile' : 'Desktop';

        gameGA && gameGA.sendDesignEvent('HP:DeviceInfo', 1, JSON.stringify(deviceInfo.vendor ? {vendor: deviceInfo.vendor} : {browserName: deviceInfo.browserName}));
        gameGA && gameGA.sendDesignEvent(`Device:${deviceType}:${deviceType === 'Mobile' ? deviceInfo.vendor : deviceInfo.browserName}:${deviceType === 'Mobile' ? deviceInfo.model : deviceInfo.browserFullVersion}`);
        gameGA && gameGA.sendDesignEvent(`Browser:${browserName}:${fullBrowserVersion}:${deviceType}`);

        ga && ga.gaEvent('device_info', deviceInfo);
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.checkPhase();

        this.sharpstarDataService
            .getOffers(undefined, 0, this.rid)
            .then((response) => {
                const {id, content} = response.offers[0];
                const adProvider = localStorage.getItem('adProvider');

                this.setState({
                    offerId: id,
                    adProvider: config.adProvider === 'dev' ? 'dev' : (adProvider || content["ad-provider"]),
                    gameConfig: {
                        buildVersion: content["game-version"],
                        buildUrl: `${config.buildPath}${content["game-version"]}/`,
                        forcedReload: content["forced-reload"],
                    },
                    preroll: {
                        showInterval: +content["show-preroll-attempt-interval"],
                        showStartAttempt: +content["show-preroll-start-attempt"],
                    },
                    pushPrompt: {
                        total: +content["push-prompt-total"],
                    }
                });

                localStorage.setItem('offerId', id);
                localStorage.setItem('buildVersion', content["game-version"]);
                !adProvider && localStorage.setItem('adProvider', content["ad-provider"]);
            })
            .catch((e) => {
                const defaults = getGameDefaults(config);

                this.setState({...defaults});
                Sentry.captureException(e);
            })
            .finally(() => {
                checkAdBlockers((adsBlocked) => this.resolveAdsBlocked(adsBlocked));
            });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    render() {
        const {
            phase,
            offerId,
            gameGA,
            ga,
            adProvider,
            adBlocker,
            preroll,
            gameConfig,
            showAdBlockerModal
        } = this.state;

        return (
            <>
                {phase === "landing" && !showAdBlockerModal &&
                    <Landing
                        onBtnClick={this.playBtnClickHandler}
                        gameGA={gameGA}
                        ga={ga}
                    />
                }
                {phase === "push" && !showAdBlockerModal && !!gameGA &&
                    <PushPhase
                        setPhase={this.setPhase}
                        updateState={this.updateState}
                        sharpstarService={this.sharpstarDataService}
                        gameGA={gameGA}
                        ga={ga}
                        params={{
                            rid: this.rid,
                            refid2: this.refid2,
                            refid3: this.refid3,
                            offerId
                        }}
                    />
                }
                {phase === "game" && !!gameConfig && !!gameGA &&
                    <GameContainer
                        gameConfig={gameConfig}
                        adProvider={adProvider}
                        preroll={preroll}
                        gameAnalytics={gameGA}
                        gaAnalytics={ga}
                    />
                }
                {showAdBlockerModal &&
                    <AdBlockerModal
                        adBlockOn={adBlocker}
                    />
                }
            </>
        );
    }
}

export default App;
