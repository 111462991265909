import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useAdService } from "App/Services/AdService";
import { setBodyOverflow, shouldDisplayPreroll } from "../../../../helpers";
import { getSecondsDifference, getWindowDimensions } from "App/helpers";
import { gameContainerBg } from "../../../../config";

/**
 * Send design event to game analytics from Unity Context
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalytics = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleSendAnalytics = useCallback((eventName, value = undefined, fieldsJson = undefined) => {
        gameAnalytics.sendDesignEvent(eventName, value, fieldsJson);
        gaAnalytics.basicGaEvent('game', eventName, value);
    }, []);

    useEffect(() => {
        addEventListener("SendAnalyticsEvent", handleSendAnalytics);

        return () => removeEventListener("SendAnalyticsEvent", handleSendAnalytics);
    }, [addEventListener, removeEventListener, handleSendAnalytics]);
}

/**
 * Send progression event to game analytics from Unity Context
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendProgressionAnalyticsEvent = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleSendProgressionAnalyticsEvent = useCallback((status, progression1, progression2, progression3, score) => {
        gameAnalytics.sendProgressionEvent(status, progression1, progression2, progression3, score);
        gaAnalytics.basicGaEvent('game', `${status} ${progression1} ${progression2} ${progression3}`, score);
    }, [gameAnalytics]);

    useEffect(() => {
        addEventListener("SendProgressionEvent", handleSendProgressionAnalyticsEvent);

        return () => removeEventListener("SendProgressionEvent", handleSendProgressionAnalyticsEvent);
    }, [addEventListener, removeEventListener, handleSendProgressionAnalyticsEvent]);
}

/**
 * Send design error event to game analytics from Unity Context
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalyticsError = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleSendAnalyticsError = useCallback((errorType, message) => {
        gameAnalytics.sendErrorEvent(errorType, message);
        gaAnalytics.basicGaEvent('error', message);
    }, []);

    useEffect(() => {
        addEventListener("SendErrorEvent", handleSendAnalyticsError);

        return () => removeEventListener("SendErrorEvent", handleSendAnalyticsError);
    }, [addEventListener, removeEventListener, handleSendAnalyticsError]);
}

export const useGameSession = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleGameEndSession = useCallback(() => {
        gameAnalytics.endGameSession();
        gaAnalytics.basicGaEvent('game', 'end_ga_analytics_session');
    }, []);

    const handleGameStartSession = useCallback(() => {
        gameAnalytics.startGameSession();
        gaAnalytics.basicGaEvent('game', 'start_ga_analytics_session');
    }, []);

    useEffect(() => {
        addEventListener("UserStartIdle", handleGameEndSession);

        return () => removeEventListener("UserStartIdle", handleGameEndSession);
    }, [addEventListener, removeEventListener, handleGameEndSession]);

    useEffect(() => {
        addEventListener("UserEndIdle", handleGameStartSession);

        return () => removeEventListener("UserEndIdle", handleGameStartSession);
    }, [addEventListener, removeEventListener, handleGameStartSession]);
}

/**
 * hook fires when game loaded, sets ad banner visibility and
 * update ad banner content (you can add any additional logic into handleLevelLoaded)
 *
 * @param centerHpBannerRef - RefObject<HTMLDivElement>
 * @param bottomHpBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useLevelLoaded = (centerHpBannerRef, bottomHpBannerRef, addEventListener, removeEventListener) => {
    const [loadedAttempt, setLoadedAttempt] = useState(0);
    const { updateAd, setBannerVisible } = useAdService();

    const handleLevelLoaded = useCallback((attempt) => {
        setLoadedAttempt((prev) => ++prev)

        if (loadedAttempt === 0) {
            setBannerVisible(centerHpBannerRef.current, true);
            setBannerVisible(bottomHpBannerRef.current, true);
            updateAd(centerHpBannerRef.current, attempt);
            updateAd(bottomHpBannerRef.current, attempt);
        }
    }, [centerHpBannerRef, bottomHpBannerRef, updateAd, setBannerVisible, loadedAttempt]);

    useEffect(() => {
        addEventListener('OnLevelLoaded', handleLevelLoaded);

        return () => removeEventListener('OnLevelLoaded', handleLevelLoaded);
    }, [addEventListener, removeEventListener, handleLevelLoaded]);
}

/**
 * hook that fires when game level started and
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param centerHpBannerRef - RefObject<HTMLDivElement>
 * @param bottomHpBannerRef - RefObject<HTMLDivElement>
 * @param bottomRefGameplayBanner - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useLevelStarted = (
    centerHpBannerRef,
    bottomHpBannerRef,
    bottomRefGameplayBanner,
    addEventListener,
    removeEventListener
) => {
    const [startedAttempt, setStartedAttempt] = useState(0);
    const { updateAd, setBannerVisible } = useAdService();

    const handleLevelStarted = useCallback((attempt) => {
        setBannerVisible(bottomRefGameplayBanner.current, true);
        updateAd(bottomRefGameplayBanner.current, attempt);
        setStartedAttempt(prev => ++prev);

        if (startedAttempt === 0) {
            setBannerVisible(centerHpBannerRef.current, false);
            setBannerVisible(bottomHpBannerRef.current, false);
        }
    }, [centerHpBannerRef, bottomHpBannerRef, bottomRefGameplayBanner, updateAd, setBannerVisible, startedAttempt]);

    useEffect(() => {
        addEventListener('OnLevelStarted', handleLevelStarted);

        return () => removeEventListener('OnLevelStarted', handleLevelStarted);
    }, [addEventListener, removeEventListener, handleLevelStarted]);
}

/**
 * hook fires when game level ends (you can add any logic into handleLevelEnded)
 *
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useLevelEnded = (addEventListener, removeEventListener) => {
    const handleLevelEnded = useCallback(() => {
        // you can add any related logic here, callback takes parameters from game - level, attempt
    }, []);

    useEffect(() => {
        addEventListener('OnLevelEnded', handleLevelEnded);

        return () => removeEventListener('OnLevelEnded', handleLevelEnded);
    }, [addEventListener, removeEventListener, handleLevelEnded]);
}

/**
 * hook fires when game result screen is showing, handler gets current game attempt as parameter
 * and handles result screen ad banners display
 *
 * @param centerRestartBannerRef - RefObject<HTMLDivElement>
 * @param bottomRestartBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param setStartPreroll - () => void (sets startPreroll state)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useResultShow = (
    centerRestartBannerRef,
    bottomRestartBannerRef,
    bottomGameplayBannerRef,
    setStartPreroll,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible, showPreroll, preroll: { showStartAttempt, showInterval } } = useAdService();

    const handleOnResultShow = useCallback((attempt) => {
        // show preroll
        if (shouldDisplayPreroll(attempt, showInterval, showStartAttempt)) {
            setStartPreroll(true);
            showPreroll(attempt);
        }

        // update banners
        setBannerVisible(centerRestartBannerRef.current, true);
        setBannerVisible(bottomRestartBannerRef.current, true);
        updateAd(centerRestartBannerRef.current, attempt);
        updateAd(bottomRestartBannerRef.current, attempt);
        setBannerVisible(bottomGameplayBannerRef.current, false);
    }, [centerRestartBannerRef, bottomRestartBannerRef, bottomGameplayBannerRef, updateAd, showStartAttempt, showInterval]);

    useEffect(() => {
        addEventListener('OnResultShow', handleOnResultShow);

        return () => removeEventListener('OnResultShow', handleOnResultShow);
    }, [addEventListener, removeEventListener, handleOnResultShow]);
}

/**
 * hook fires when game result screen is closed,
 *
 * @param centerRestartBannerRef - RefObject<HTMLDivElement>
 * @param bottomRestartBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useResultHide = (
    centerRestartBannerRef,
    bottomRestartBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { setBannerVisible } = useAdService();

    const handleOnResultHide = useCallback(() => {
        setBannerVisible(centerRestartBannerRef.current, false);
        setBannerVisible(bottomRestartBannerRef.current, false);
    }, [centerRestartBannerRef, bottomRestartBannerRef, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnResultHide', handleOnResultHide);

        return () => removeEventListener('OnResultHide', handleOnResultHide);
    }, [addEventListener, removeEventListener, handleOnResultHide]);
}

/**
 * hook fires when game pause screen is showing, update ad banner content,
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param centerPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const usePauseShow = (
    centerPauseBannerRef,
    bottomPauseBannerRef,
    bottomGameplayBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnPauseShow = useCallback((attempt) => {
        setBannerVisible(centerPauseBannerRef.current, true);
        setBannerVisible(bottomPauseBannerRef.current, true);
        updateAd(centerPauseBannerRef.current, attempt);
        updateAd(bottomPauseBannerRef.current, attempt);
        setBannerVisible(bottomGameplayBannerRef.current, false);
    }, [centerPauseBannerRef, bottomPauseBannerRef, bottomGameplayBannerRef, setBannerVisible, updateAd]);

    useEffect(() => {
        addEventListener('OnPauseShow', handleOnPauseShow);

        return () => removeEventListener('OnPauseShow', handleOnPauseShow);
    }, [addEventListener, removeEventListener, handleOnPauseShow]);
}

/**
 * hook fires when game pause screen is hiding, update ad banner content,
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param centerPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const usePauseHide = (
    centerPauseBannerRef,
    bottomPauseBannerRef,
    bottomGameplayBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnPauseHide = useCallback((attempt) => {
        setBannerVisible(centerPauseBannerRef.current, false);
        setBannerVisible(bottomPauseBannerRef.current, false);
        setBannerVisible(bottomGameplayBannerRef.current, true);
        updateAd(bottomGameplayBannerRef.current, attempt);
    }, [centerPauseBannerRef, bottomPauseBannerRef, bottomGameplayBannerRef, updateAd, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnPauseHide', handleOnPauseHide);

        return () => removeEventListener('OnPauseHide', handleOnPauseHide);
    }, [addEventListener, removeEventListener, handleOnPauseHide]);
}

/**
 * hook fires when skin customization game screen is showing, update ad banner content,
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param refHpBannerCenter - RefObject<HTMLDivElement>
 * @param refRestartBannerCenter - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useCustomisationOpen = (
    refHpBannerCenter,
    refRestartBannerCenter,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnCustomisationOpen = useCallback((attempt, prevGameScreen) => {
        prevGameScreen === 'Home' && setBannerVisible(refHpBannerCenter.current, false);
        prevGameScreen === 'Result' && setBannerVisible(refRestartBannerCenter.current, false);
    }, [refHpBannerCenter, refRestartBannerCenter, setBannerVisible, updateAd]);

    useEffect(() => {
        addEventListener('OnCustomisationOpen', handleOnCustomisationOpen);

        return () => removeEventListener('OnCustomisationOpen', handleOnCustomisationOpen);
    }, [addEventListener, removeEventListener, handleOnCustomisationOpen]);
}

/**
 * hook fires when skin customization game screen is closed, update ad banner content,
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param refHpBannerCenter - RefObject<HTMLDivElement>
 * @param refRestartBannerCenter - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useCustomisationClose = (
    refHpBannerCenter,
    refRestartBannerCenter,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleCustomisationClose = useCallback((attempt, prevGameScreen) => {
        if (prevGameScreen === 'Home') {
            setBannerVisible(refHpBannerCenter.current, true);
            updateAd(refHpBannerCenter.current, attempt);
        }
        if (prevGameScreen === 'Result') {
            updateAd(refRestartBannerCenter.current, attempt);
            setBannerVisible(refRestartBannerCenter.current, true);
        }
    }, [refHpBannerCenter, refRestartBannerCenter, setBannerVisible, updateAd]);

    useEffect(() => {
        addEventListener('OnCustomisationClose', handleCustomisationClose );

        return () => removeEventListener('OnCustomisationClose', handleCustomisationClose );
    }, [addEventListener, removeEventListener, handleCustomisationClose ]);
}

/**
 * hook fires when rewarded video ad is called
 *
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useRewardStarted = (addEventListener, removeEventListener) => {
    const { showRewardedVideo } = useAdService();

    const handleOnRewardStarted = useCallback((attempt, rewardType) => {
        showRewardedVideo(rewardType, attempt);
    }, [showRewardedVideo]);

    useEffect(() => {
        addEventListener('OnRewardStarted', handleOnRewardStarted);

        return () => removeEventListener('OnRewardStarted', handleOnRewardStarted);
    }, [addEventListener, removeEventListener, handleOnRewardStarted]);
}

/**
 * hook fires on game background change sets game container background color
 * with matching game background color (you can add any additional logic into handleOnColorChange)
 *
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 *
 * @return string
 */
export const useColorChange = (addEventListener, removeEventListener) => {
    const [gameBgColor, setGameBgColor] = useState('#440494');

    const handleOnColorChange = useCallback((color) => {
        setGameBgColor(gameContainerBg[color])
    }, [setGameBgColor]);

    useEffect(() => {
        addEventListener('OnColorChange', handleOnColorChange);

        return () => removeEventListener('OnColorChange', handleOnColorChange);
    }, [addEventListener, removeEventListener, handleOnColorChange]);

    return gameBgColor;
}

/**
 * hook checks game loading time and sends it with analytics events
 *
 * @param isLoaded - boolean
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 */
export const useLoadingTime = (isLoaded, gameAnalytics, gaAnalytics) => {
    const [startLoadingTime, setStartLoadingTime] = useState(null);

    useEffect(() => {
        setStartLoadingTime(new Date());
    }, [])

    useEffect(() => {
        if (isLoaded) {
            const loadingTime = getSecondsDifference(startLoadingTime, new Date());

            gameAnalytics.sendDesignEvent('Game:LoadingTime', loadingTime);
            gaAnalytics.basicGaEvent('game', 'loading_time', loadingTime);
        }
    }, [isLoaded])
}

/**
 * hook dynamically sets game canvas dimensions depending on device type
 *
 * @return object - { canvasWidth: string, canvasHeight: string }
 */
export const useSetGameCanvasDimensions = () => {
    const [canvasDimensions, setCanvasDimensions] = useState({
        canvasWidth: '',
        canvasHeight: '',
    });

    useEffect(() => {
        const { width, height } = getWindowDimensions();

        if (isMobile) {
            setBodyOverflow({ overflow: 'hidden' });
            setCanvasDimensions({
                canvasWidth: '100%',
                canvasHeight: `${height}px`
            })
        } else {
            if (width >= height || width / height > 0.5625) {
                setCanvasDimensions({
                    canvasWidth: `${height * 9 / 16}px`,
                    canvasHeight: '100%'
                })
            } else {
                setCanvasDimensions({
                    canvasWidth: '100%',
                    canvasHeight: `${width * 16 / 9}px`
                })
            }
        }
    }, []);

    return { ...canvasDimensions };
}

/**
 * hook gives focus back to game canvas on mouse down event
 */
export const useGameFocus = (canvasRef) => {
    useEffect(() => {
        canvasRef.current && canvasRef.current.addEventListener("mousedown", handleMouseDown, false);

        return () => {
            canvasRef.current.removeEventListener('mousedown', handleMouseDown);
        };
    }, [])

    const handleMouseDown = () => {
        window.focus();
    }
}
