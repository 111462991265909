import React, {useEffect, useState} from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import AdService from "App/Services/AdService";
import { getDeviceOrientation, getWindowDimensions } from "App/helpers";
import BubbleLoader from "App/React/_UI/BubbleLoader";
import OrientationMessage from "../../../OrientationMessage";
import { vliRewardIdsMap } from "../../../../config";
import Game from "../Game";

const GameContainer = ({ gameAnalytics, gaAnalytics, gameConfig, preroll, adProvider }) => {
    const [orientation, setOrientation] = useState(null);
    const [blockGameRender, setBlockGameRender] = useState(false);
    const [orientationMessage, setOrientationMessage] = useState(false);

    useEffect(() => {
        const firstRenderOrientation = getDeviceOrientation();

        if (isMobile && firstRenderOrientation.startsWith('landscape')) {
            window.scrollTo(0, 0);
            setBlockGameRender(true);
        }

        setOrientation(firstRenderOrientation);
    }, []);

    useEffect(() => {
        if (orientation) {
            gameAnalytics.sendDesignEvent('Game:ScreenOrientation', orientation.startsWith('portrait') ? 0 : 1);
            gaAnalytics.basicGaEvent('game', 'screen_orientation', orientation.startsWith('portrait') ? 0 : 1);

            if (orientation.startsWith("landscape") && isMobile) {
                gameAnalytics.sendDesignEvent('Game:ScreenLandscapeWarning');
                gaAnalytics.basicGaEvent('game', 'screen_landscape_warning');
            }
        }
    }, [orientation]);

    useEffect(() => {
        if (isMobile) {
            const handleOrientationChange = (event) => {
                const type = screen.orientation
                    ? (event ? event.target.type : screen.orientation.type)
                    : (window.orientation === 0 || window.orientation === 180 ? 'portrait-primary' : 'landscape-primary');

                setOrientation(type);
            };

            if (screen.orientation) {
                screen.orientation.addEventListener("change", handleOrientationChange);

                return () => screen.orientation.removeEventListener("change", handleOrientationChange);
            } else {
                window.addEventListener('orientationchange', handleOrientationChange);

                return () => window.removeEventListener('orientationchange', handleOrientationChange);
            }
        }
    }, []);

    useEffect(() => {
        if (orientation && isMobile) {
            if (orientation.startsWith("portrait")) {
                blockGameRender && setBlockGameRender(false);
                orientationMessage && setOrientationMessage(false);
            } else {
                const { height} = getWindowDimensions();
                let vh = height * 0.01;

                document.documentElement.style.setProperty('--vh', `${vh}px`);

                setOrientationMessage(true);
                gameAnalytics.sendDesignEvent('Game:ScreenLandscapeWarning');
                gaAnalytics.basicGaEvent('game', 'screen_landscape_warning');
            }
        }
    }, [orientation])

    if (!orientation) return <BubbleLoader backgroundColor={'#440494'} loaderColor={'#FFFFFF'} />;
    if (blockGameRender) return <OrientationMessage visible={orientationMessage} />;

    return (
        <>
            { orientationMessage &&
                <OrientationMessage visible={orientationMessage} />
            }
            {
                adProvider === 'adinplay' && (
                    <Helmet>
                        <script async type="text/javascript" src="//api.adinplay.com/libs/aiptag/pub/RYM/snakecolorbrake.com/tag.min.js"></script>
                    </Helmet>
                )
            }
            {
                adProvider === 'vli' && (
                    <Helmet>
                        <script type="text/javascript" src="//cdn.vlitag.com/w/c2fb798d-6385-4c5c-8e8f-de96e1b1da11.js" async defer></script>
                    </Helmet>
                )
            }
            <AdService
                rewardIds={vliRewardIdsMap}
                adProvider={adProvider}
                gameGA={gameAnalytics}
                ga={gaAnalytics}
                preroll={preroll}
            >
                <Game
                    adProvider={adProvider}
                    gameAnalytics={gameAnalytics}
                    gaAnalytics={gaAnalytics}
                    gameConfig={gameConfig}
                    orientation={orientation}
                />
            </AdService>
        </>
    )
}

export default GameContainer;
