import React, { useRef } from "react";
import {
    useLevelLoaded,
    useLevelStarted,
    useResultHide,
    useResultShow,
    usePauseHide,
    usePauseShow,
    useCustomisationClose,
    useCustomisationOpen,
} from "../Game/hooks";

const AdinplayBanners = ({ setStartPreroll, addEventListener, removeEventListener }) => {
    const refHpBannerCenter = useRef(null);
    const refHpBannerBottom = useRef(null);
    const refGameplayBannerBottom = useRef(null);
    const refPauseBannerCenter = useRef(null);
    const refPauseBannerBottom = useRef(null);
    const refRestartBannerCenter = useRef(null);
    const refRestartBannerBottom = useRef(null);

    useLevelLoaded(refHpBannerCenter, refHpBannerBottom, addEventListener, removeEventListener);
    useLevelStarted(refHpBannerCenter, refHpBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    useResultShow(refRestartBannerCenter, refRestartBannerBottom, refGameplayBannerBottom, setStartPreroll, addEventListener, removeEventListener);
    useResultHide(refRestartBannerCenter, refRestartBannerBottom, addEventListener, removeEventListener);
    usePauseShow(refPauseBannerCenter, refPauseBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    usePauseHide(refPauseBannerCenter, refPauseBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    useCustomisationOpen(refHpBannerCenter, refRestartBannerCenter, addEventListener, removeEventListener);
    useCustomisationClose(refHpBannerCenter, refRestartBannerCenter, addEventListener, removeEventListener);

    return (
        <>
            {/* adinplay video ad elements */}
            <div id="snakecolorbrake-com_preroll" />
            {/* adinplay ad center banners */}
            <div
                ref={refHpBannerCenter}
                data-ga-id="snakecolorbrake_300x250_HP"
                className="center-banner">
                <div id="snakecolorbrake-com_300x250_HP" />
            </div>
            <div
                ref={refRestartBannerCenter}
                data-ga-id="snakecolorbrake_300x250_restart"
                className="center-banner">
                <div id="snakecolorbrake-com_300x250_restart" />
            </div>
            <div
                ref={refPauseBannerCenter}
                data-ga-id="snakecolorbrake_300x250_pause"
                className="center-banner">
                <div id="snakecolorbrake-com_300x250_pause" />
            </div>
            {/* adinplay ad bottom banners */}
            <div
                ref={refHpBannerBottom}
                data-ga-id="snakecolorbrake_320x50_HP"
                className="footer-banner-hp">
                <div id="snakecolorbrake-com_320x50_HP" />
            </div>
            <div
                ref={refGameplayBannerBottom}
                data-ga-id="snakecolorbrake_320x50_gameplay"
                className="footer-banner-gameplay">
                <div id="snakecolorbrake-com_320x50_gameplay" />
            </div>
            <div
                ref={refRestartBannerBottom}
                data-ga-id="snakecolorbrake_320x50_restart"
                className="footer-banner-restart">
                <div id="snakecolorbrake-com_320x50_restart" />
            </div>
            <div
                ref={refPauseBannerBottom}
                data-ga-id="snakecolorbrake_320x50_pause"
                className="footer-banner-pause">
                <div id="snakecolorbrake-com_320x50_pause"/>
            </div>
        </>
    )
};

export default AdinplayBanners;