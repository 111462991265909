import {isLocal, environment} from "App/Helpers/enviroments";

const env = environment();
let config;

const baseConfig = {
    buildVersion: '1.0.72.1',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    terms: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=terms',
    privacy: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=privacy-policy',
    sentryEnabled: true,
    adProvider: 'dev', // change to 'ad provider' name if ads need to be tested on dev otherwise keep 'dev' value
    lang: "en",
}

const local = {
    ...baseConfig,
    campaignId: 25,
    rid: 108,
    source: "Snake Unity Web Local",
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'http://127.0.0.1:8080/',
    buildPath: 'http://rm-snakecolorbrake-game/Build/',
    oneSignalAppId: "cd43657e-9a63-4013-b24e-fa3486f748b0", // OneSignal dev app name: 'Localhost CY Development'
    gaTrackId: 'G-7QMGB80HXS', // app name: Games-Dev-ALL; stream name: Games Dev
};

// dev rid = 119
// lena rid = 129
const dev = {
    ...baseConfig,
    campaignId: 25,
    rid: 119,
    source: "Snake Unity Web Dev",
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'https://game-dev.snakecolorbrake.com/',
    buildPath: 'https://game-dev.snakecolorbrake.com/build/',
    oneSignalAppId: "4aee9612-829c-470f-b497-4b935fac6ecd", // OneSignal dev app name: Games_pwa US Development
    gaTrackId: 'G-7QMGB80HXS', // app name: Games-Dev-ALL; stream name: Games Dev
};

const prod = {
    ...baseConfig,
    campaignId: 28,
    rid: 118,
    source: "Snake Unity Web Prod",
    adProvider: 'vli',
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    domain: 'https://snakecolorbrake.com/',
    buildPath: 'https://snakecolorbrake.com/game/',
    gaTrackId: 'G-TW3JV6VRDL', // app name: Snake Color Break GA4; stream name: snakecolorbrake.com
    oneSignalAppId: "a61cec45-edfe-4f0d-b896-78a3b003779a", // OneSignal prod app name: Games_web US Production
};

if (env === 'production') {
    config = prod;
} else if (env === 'development') {
    config = dev;
} else {
    config = local;
}

// add userId value if needed
export const gameAnalyticsConfig = {
    gameKey: "11e2d22cd4ccd6441751c221ade147f1",
    gameSecret: "b60ab3b934993cad95037de83d3f317989212b26",
}

export const oneSignalConfig = {
    appId: config.oneSignalAppId,
    safari_web_id: env === 'production' ? 'web.onesignal.auto.5d56d362-2565-48e1-9c7d-b5c325eeeb04' : "web.onesignal.auto.52bd6d36-ef00-42e1-a687-b4f3eaae4ff3",
    serviceWorkerPath: isLocal() ? 'OneSignalSDKWorker.js' : 'push/OneSignalSDKWorker.js',
    serviceWorkerParam: {
        scope: isLocal() ? '/' : '/push/'
    },
    notifyButton: {
        enable: false,
    },
    welcomeNotification: {
        disable: true,
    },
    allowLocalhostAsSecureOrigin: isLocal(),
    autoRegister: false,
}

export const gameContainerBg = {
    "D9D3CE": "#F7F1EC",
    "FFBEBD": "#FFEEED",
    "AED9FF": "#C2EDFF",
    "FFFDA8": "#FFFEE5",
    "161414": "#2A2828",
    "FFFFFF": "rgba(117, 8, 255, .05)",
    "31006F": "#440494",
}

export const vliRewardIdsMap = {
    'revive': 'pw_17238',
    'speedDown': 'pw_18312',
    'plus15': 'pw_18313',
    'extraCoins': 'pw_18528',
}

export default config;
