import React, {useEffect, useState} from 'react';
import {getRandomElement} from "App/helpers";
import logo from '../../../../assets/logo.svg';
import freeIcon from "../../../../assets/free-icon.svg";
import registrationIcon from "../../../../assets/registration-icon.svg";
import permissionIcon from "../../../../assets/permission-icon.svg";
import play from "../../../../assets/play.svg";
import snakeGreen from "../../../../assets/snake-green.png";
import snakeCyan from "../../../../assets/snake-cyan.png";
import snakePink from "../../../../assets/snake-pink.png";
import snakeYellow from "../../../../assets/snake-yellow.png";
import snakeBlue from "../../../../assets/snake-blue.png";
import './styles.scss';

const snakeImgs = [
    {
        snake: snakeGreen,
        alt: 'play with green snake'
    },
    {
        snake: snakeCyan,
        alt: 'play with snake cyan'
    },
    {
        snake: snakePink,
        alt: 'play with pink snake'
    },
    {
        snake: snakeYellow,
        alt: 'play with yellow snake'
    },
    {
        snake: snakeBlue,
        alt: 'play with blue snake'
    }];

const colors = ['green', 'cyan', 'pink', 'yellow', 'blue'];

const Hero = ({onBtnClick, gameGA, ga}) => {
    const [pulseIndex, setPulseIndex] = useState(3);
    const [snakeColor, setSnakeColor] = useState(null);

    useEffect(() => {
        let timeout;

        if (snakeColor) {
            setPulseIndex(null);

        } else {
            timeout = setTimeout(() => {
                const element = getRandomElement(5, pulseIndex);

                setPulseIndex(element);
            }, 1900);
        }

        return () => clearTimeout(timeout);
    }, [pulseIndex, snakeColor])


    const selectSnake = (color) => {
        gameGA && gameGA.sendDesignEvent('HP:SnakeSelected', colors.indexOf(color));
        ga && ga.gaEvent('snake_selected', {color});
        setSnakeColor(color);
    }

    return (
        <section className={"hero_wrapper"}>
            <div className={"hero_header"}>
                <img fetchpriority="high"  src={logo} width={287} height={102} alt="snake game logo" className={"hero_logo"}/>
            </div>
            <div className={"hero_content"}>
                <h1 className={"hero_title"}>Choose your snake and play instantly</h1>
                <div className={"hero_snakesRow"}>
                    {
                        snakeImgs.length > 0 && snakeImgs.map(({snake, alt}, index) => {
                            return (
                                <div className={"hero_snakesCol"} key={index}>
                                    <div className={colors.indexOf(snakeColor) === index ? 'selected' : ''}>
                                        <img
                                            src={snake}
                                            alt={alt}
                                            fetchpriority="high"
                                            className={index === pulseIndex ? 'hero_snake pulse' : 'hero_snake'}
                                            onClick={() => selectSnake(colors[index])}
                                            width={47} height={96}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <button onClick={() => onBtnClick()} className={"play-button buttom"}>
                        Play For Free <img fetchpriority="high"  src={play} alt="play" width={14} height={17} className={"button-emoji"}/>
                    </button>
                </div>
                <div className={"hero_row"}>
                    <div className={"hero_column"}>
                        <img fetchpriority="high"  src={freeIcon} width={47} height={47} alt="free icon" className={"hero_icon"}/>
                        <div className={"hero_span"}><em>100% Free - No Credit Card Required</em></div>
                    </div>
                    <div className={"hero_column"}>
                        <img fetchpriority="high"  src={registrationIcon} width={47} height={47} alt="snake game registration"
                             className={"hero_icon"}/>
                        <div className={"hero_span"}><em>No Registration Needed</em></div>
                    </div>
                    <div className={"hero_column"}>
                        <img fetchpriority="high"  src={permissionIcon} width={47} height={47} alt="snake game permission"
                             className={"hero_icon"}/>
                        <div className={"hero_span"}><em>No App Permissions</em></div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Hero;