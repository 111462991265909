export const getRandomElement = (max, prev) => {
    const newNum =  Math.floor(Math.random() * max);

    return newNum === prev ? getRandomElement(max, prev) : newNum;
}

export const getUrlParamsObj = (url, pageType = 'gambling') => {
    const urlParams = new URLSearchParams(url);
    const urlParamsObj = {};
    const paramNames = ['refid1', 'refid2', 'refid3'];

    const getParamValue = (name) => {
        const value = urlParams.get(name) || '0';

        if (value !== '0') {
            localStorage.setItem(name, value);
        } else if (localStorage.getItem(name)) {
            return localStorage.getItem(name);
        }

        return value;
    };

    const rid = urlParams.get('rid');

    if (pageType === 'gambling') {
        urlParamsObj['rid'] = rid || null;
        urlParamsObj['hash'] = urlParams.get('hash') || undefined;
    } else if (pageType === 'game') {
        if (rid) {
            urlParamsObj['rid'] = rid;
            localStorage.setItem('rid', rid);
        } else {
            urlParamsObj['rid'] = localStorage.getItem('rid') || null;
        }
    }

    paramNames.forEach((name) => {
        urlParamsObj[name] = getParamValue(name);
    });

    return urlParamsObj;
};

export const arrayFromNumber = (number) => {
    return Array.from({length: +number}, (_, i) => i + 1)
}

export const setBodyOverflow = ({ overflow }) => {
    document.documentElement.style.setProperty('--overflow', overflow);
}

export const getSecondsDifference = (startDate, endDate) => {
    return +(Math.abs(endDate - startDate)/1000).toFixed(2);
}

export function getWindowDimensions() {
    return { width: window.innerWidth, height: window.innerHeight };
}

export const getDeviceOrientation = () => {
    return screen.orientation ? screen.orientation.type :
        (window.orientation === 0 || window.orientation === 180 ? 'portrait-primary' : 'landscape-primary');
}

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

export const capitalize = (str) => {
  return str[0].toUpperCase() + str.slice(1);
}

export const isWebView = () => {
    if (typeof window === undefined) { return false }

    let navigator = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
    const ios_ipad_webview = ios && !safari;

    return ios ? ( (!standalone && !safari) || ios_ipad_webview ) : userAgent.includes('wv');
}

export const handleCacheControl = (url) => {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
        return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
        return "immutable";
    }
    return "no-store";
}
