import React from "react";
import rotateIcon from "../../assets/rotate-icon.svg";
import './styles.scss';

const OrientationMessage = ({ visible }) => {
  return (
      <div className={`orientation-message${visible ? ' show' : ''}`}>
          <div className={"orientation-message_container"}>
              <div className={"orientation-message_image"}>
                  <img src={rotateIcon} alt="rotate icon"/>
              </div>
              <p className={"orientation-message_text"}>Please rotate your device</p>
              <p className={"orientation-message_text yellow"}>to portrait mode!</p>
          </div>
      </div>
  )
};

export default OrientationMessage;