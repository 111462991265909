import { isLocal, environment } from "App/Helpers/enviroments";

const env = environment();
let config;

const baseConfig = {
    sourceName: 'Bacana Gol Unity Web',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    terms: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=termos-de-uso',
    privacy: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=politica-de-privacidade',
    sentryEnabled: true,
    adProvider: 'dev', // change to 'ad provider' name if ads need to be tested on dev otherwise keep 'dev' value
    buildVersion: '1.0.16.7',
    gameAnalyticsKey: 'a54d03eeebbf057b8b486126e7e395a5', // link to gameGA app - https://tool.gameanalytics.com/game/266513/
    gameAnalyticsSecret: '98131f4980849c8fa568756d53984384d0d5565a', // link to gameGA app -https://tool.gameanalytics.com/game/266513/
    isStage: false,
}

const local = {
    ...baseConfig,
    rid: 115,
    campaignId: 38,
    source: `${baseConfig.sourceName} Local`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'http://127.0.0.1:8080/',
    buildPath: '',
    oneSignalAppId: "cd43657e-9a63-4013-b24e-fa3486f748b0", // OneSignal dev app name: 'Localhost CY Development'
    safariWebId: 'web.onesignal.auto.01b20842-ed7c-48c4-bd42-e78491d78625',
    gaTrackId: 'G-7QMGB80HXS', // app name: Games-Dev-ALL; stream name: Games Dev
};

// change campaignId and rid value depending on what setup you expect
// stage-prod campaignId: 61,
// stage-prod rid: 128,
// dev campaignId: 41,
// dev rid = 126
// dev rid lena = 127
// dev rid game-team = 124
const dev = {
    ...baseConfig,
    rid: 126,
    campaignId: 41,
    source: `${baseConfig.sourceName} Dev`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: `https://${baseConfig.isStage ? 'stage' : 'games-dev'}.bacanagol.com/`,
    buildPath: `https://${baseConfig.isStage ? 'stage' : 'games-dev'}.bacanagol.com/build/`,
    oneSignalAppId: `${baseConfig.isStage ? 'f8f57d1b-6f37-418d-ac2c-aad29f2e5765' : '79f05cec-6da5-4472-a0ab-0e9ca6c2e8a1'}`, // OneSignal dev app name if stage is 'Test BR Development' else - 'Development_games CY Development'
    safariWebId: `${baseConfig.isStage ? 'web.onesignal.auto.0a17e090-f65a-43cf-871a-056959ed633a' : 'web.onesignal.auto.1a1cd43f-104c-4ac2-b069-e07775f1eeb9'}`,
    gaTrackId: 'G-7QMGB80HXS', // app name: Games-Dev-ALL; stream name: Games Dev
};

const prod = {
    ...baseConfig,
    rid: 127,
    campaignId: 43,
    source: `${baseConfig.sourceName} Prod`,
    adProvider: 'vli',
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    domain: 'https://bacanagol.com/',
    buildPath: 'https://bacanagol.com/game/',
    oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
    safariWebId: 'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
    gaTrackId: 'G-EYRMRLHD2R', // app name: Football-BR
};

if (env === 'production') {
    config = prod;
} else if (env === 'development') {
    config = dev;
} else {
    config = local;
}

export const oneSignalConfig = {
    appId: config.oneSignalAppId,
    safari_web_id: config.safariWebId,
    serviceWorkerPath: isLocal() ? 'OneSignalSDKWorker.js' : 'push/OneSignalSDKWorker.js',
    serviceWorkerParam: {
        scope: isLocal() ? '/' : '/push/'
    },
    notifyButton: {
        enable: false,
    },
    welcomeNotification: {
        disable: true,
    },
    allowLocalhostAsSecureOrigin: isLocal(),
    autoRegister: false,
}

export const vliRewardIdsMap = {
    'Plus500Coins': '',
    'Plus1Bag': '',
    'GetThirdKey': '',
    'CollectAndSkipLevel': '',
    'MultiplyCoins': '',
}

export default config;
