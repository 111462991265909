import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/browser";
import {
    getSecondsDifference,
    getWindowDimensions,
    getDeviceOrientation,
    setBodyOverflow
} from "../helpers";
import config from "Pages/games/aq/web/football/config";

/**
 * hook dynamically sets game canvas dimensions depending on device type
 *
 * @return object - { canvasWidth: string, canvasHeight: string }
 */
export const useSetGameCanvasDimensions = () => {
    const [canvasDimensions, setCanvasDimensions] = useState({
        canvasWidth: '',
        canvasHeight: '',
    });

    useEffect(() => {
        const { width, height } = getWindowDimensions();

        if (isMobile) {
            setBodyOverflow({ overflow: 'hidden' });
            setCanvasDimensions({
                canvasWidth: '100%',
                canvasHeight: `${height}px`
            })
        } else {
            if (width >= height || width / height > 0.5625) {
                setCanvasDimensions({
                    canvasWidth: `${height * 9 / 16}px`,
                    canvasHeight: '100%'
                })
            } else {
                setCanvasDimensions({
                    canvasWidth: '100%',
                    canvasHeight: `${width * 16 / 9}px`
                })
            }
        }
    }, []);

    return { ...canvasDimensions };
}

/**
 * hook gives focus back to game canvas on mouse down event
 *
 * @param canvasRef - MutableRefObject<null>
 */
export const useGameFocus = (canvasRef) => {
    useEffect(() => {
        canvasRef.current && canvasRef.current.addEventListener("mousedown", handleMouseDown, false);

        return () => {
            canvasRef.current.removeEventListener('mousedown', handleMouseDown);
        };
    }, [])

    const handleMouseDown = () => {
        window.focus();
    }
}

/**
 * hook checks game loading time and sends it with analytics events
 *
 * @param isLoaded - boolean
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 */
export const useLoadingTime = (isLoaded, gameAnalytics, gaAnalytics) => {
    const [startLoadingTime, setStartLoadingTime] = useState(null);

    useEffect(() => {
        setStartLoadingTime(new Date());
    }, [])

    useEffect(() => {
        if (isLoaded) {
            const loadingTime = getSecondsDifference(startLoadingTime, new Date());

            gameAnalytics.sendDesignEvent('Game:LoadingTime', loadingTime);
            gaAnalytics.basicGaEvent('game', 'loading_time', loadingTime);
        }
    }, [isLoaded])
}

/**
 * hook watches screen orientation changes
 *
 * @param gameGA - new () => Class (initialized Game Analytics Class instance)
 * @param ga - new () => Class (initialized Google Analytics Class instance)
 *
 * @return { orientation: 'portrait-primary' || 'landscape-primary', blockGameRender: boolean, orientationMessage: boolean }
 */
export const useDeviceOrientation = (gameGA, ga) => {
    const [orientation, setOrientation] = useState(null);
    const [blockGameRender, setBlockGameRender] = useState(false);
    const [orientationMessage, setOrientationMessage] = useState(false);

    // check and set first screen orientation, block game render if "landscape"
    useEffect(() => {
        const firstRenderOrientation = getDeviceOrientation();

        if (isMobile && firstRenderOrientation.startsWith('landscape')) {
            window.scrollTo(0, 0);
            setBlockGameRender(true);
        }

        setOrientation(firstRenderOrientation);
    }, []);

    // set screen orientation listener if device is mobile type
    useEffect(() => {
        if (isMobile) {
            const handleOrientationChange = (event) => {
                const type = screen.orientation
                    ? (event ? event.target.type : screen.orientation.type)
                    : (window.orientation === 0 || window.orientation === 180 ? 'portrait-primary' : 'landscape-primary');

                setOrientation(type);
            };

            if (screen.orientation) {
                screen.orientation.addEventListener("change", handleOrientationChange);

                return () => screen.orientation.removeEventListener("change", handleOrientationChange);
            } else {
                window.addEventListener('orientationchange', handleOrientationChange);

                return () => window.removeEventListener('orientationchange', handleOrientationChange);
            }
        }
    }, []);

    // listens for screen orientation changes
    useEffect(() => {
        if (orientation) {
            gameGA.sendDesignEvent('Game:ScreenOrientation', orientation.startsWith('portrait') ? 0 : 1);
            ga.basicGaEvent('game', 'screen_orientation', orientation.startsWith('portrait') ? 0 : 1);

            if (orientation.startsWith("landscape") && isMobile) {
                gameGA.sendDesignEvent('Game:ScreenLandscapeWarning');
                ga.basicGaEvent('game', 'screen_landscape_warning');
            }
        }

        if (orientation && isMobile) {
            if (orientation.startsWith("portrait")) {
                blockGameRender && setBlockGameRender(false);
                orientationMessage && setOrientationMessage(false);
            } else {
                const { height} = getWindowDimensions();
                let vh = height * 0.01;

                document.documentElement.style.setProperty('--vh', `${vh}px`);

                setOrientationMessage(true);
                gameGA.sendDesignEvent('Game:ScreenLandscapeWarning');
                ga.basicGaEvent('game', 'screen_landscape_warning');
            }
        }
    }, [orientation])

    return { orientation, blockGameRender, orientationMessage }
}

/**
 * Send design event to game analytics from Unity Context
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalytics = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleSendAnalytics = useCallback((eventName, value = undefined, fieldsJson = undefined) => {
        gameAnalytics.sendDesignEvent(eventName, value, fieldsJson);
        gaAnalytics.basicGaEvent('game', eventName, value);
    }, []);

    useEffect(() => {
        addEventListener("SendAnalyticsEvent", handleSendAnalytics);

        return () => removeEventListener("SendAnalyticsEvent", handleSendAnalytics);
    }, [addEventListener, removeEventListener, handleSendAnalytics]);
}

/**
 * Send progression event to game analytics from Unity Context
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendProgressionAnalyticsEvent = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleSendProgressionAnalyticsEvent = useCallback((status, progression1, progression2, progression3, score) => {
        gameAnalytics.sendProgressionEvent(status, progression1, progression2, progression3, score);
        gaAnalytics.basicGaEvent('game', `${status} ${progression1} ${progression2} ${progression3}`, score);
    }, [gameAnalytics]);

    useEffect(() => {
        addEventListener("SendProgressionEvent", handleSendProgressionAnalyticsEvent);

        return () => removeEventListener("SendProgressionEvent", handleSendProgressionAnalyticsEvent);
    }, [addEventListener, removeEventListener, handleSendProgressionAnalyticsEvent]);
}

/**
 * hook that sends design error event to game analytics from Unity Context
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalyticsError = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleSendAnalyticsError = useCallback((errorType, message) => {
        gameAnalytics.sendErrorEvent(errorType, message);
        gaAnalytics.basicGaEvent('error', message);
    }, []);

    useEffect(() => {
        addEventListener("SendErrorEvent", handleSendAnalyticsError);

        return () => removeEventListener("SendErrorEvent", handleSendAnalyticsError);
    }, [addEventListener, removeEventListener, handleSendAnalyticsError]);
}


/**
 * hook that starts or ends Game Analytics session
 *
 * @param gameAnalytics - new () => Class (initialized GameAnalytics Class instance)
 * @param gaAnalytics - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useGameSession = (gameAnalytics, gaAnalytics, addEventListener, removeEventListener) => {
    const handleGameEndSession = useCallback(() => {
        gameAnalytics.endGameSession();
        gaAnalytics.basicGaEvent('game', 'end_ga_analytics_session');
    }, []);

    const handleGameStartSession = useCallback(() => {
        gameAnalytics.startGameSession();
        gaAnalytics.basicGaEvent('game', 'start_ga_analytics_session');
    }, []);

    useEffect(() => {
        addEventListener("UserStartIdle", handleGameEndSession);

        return () => removeEventListener("UserStartIdle", handleGameEndSession);
    }, [addEventListener, removeEventListener, handleGameEndSession]);

    useEffect(() => {
        addEventListener("UserEndIdle", handleGameStartSession);

        return () => removeEventListener("UserEndIdle", handleGameStartSession);
    }, [addEventListener, removeEventListener, handleGameStartSession]);
}

/**
 * hook that loads and handles terms of use and privacy policy modal content
 */
export const useTermsAndPrivacyContent = () => {
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showTermsUse = async (element, type) => {
        element.preventDefault();
        !!modalContent && setModalContent(null);
        setIsModalOpen(true);

        try {
            // fetch local prebuild html modal content file
            const response = await fetch(element.target.href);

            if (response.status === 200) {
                const data = await response.text();

                if (data) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(data, "text/html");

                    setModalContent({
                        title: doc.title,
                        content: doc.body.innerHTML
                    });
                    return;
                }
            }

            // call outside fallback modal content link if fetch local modal content file failed
            const fallbackResponse = await fetch(config[type]);
            const fallbackData = await fallbackResponse.json();

            if (fallbackData.length) {
                const data = fallbackData[0];

                setModalContent({
                    title: data.title.rendered,
                    content: data.content.rendered
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    const hideTermsUse = () => {
        setIsModalOpen(false);
        setModalContent(null);
    }

    return [modalContent, isModalOpen, showTermsUse, hideTermsUse];
}

/**
 * hook sets a window resize event listener and displays ad side banners based on available window space
 *
 * @param sideContainerRef -  MutableRefObject<null>
 * @param gameGA - new () => Class (initialized GameAnalytics Class instance)
 * @param ga - new () => Class (initialized GoogleAnalytics Class instance)
 */
export const useResizeListenerForBanners = (sideContainerRef, gameGA, ga) => {
    const [sideBanner, setSideBanner] = useState(false);
    const [sideVideo, setSideVideo] = useState(false);

    useEffect(() => {
        let resizeCalled = false;

        const setIsResized = () => {
            if (!resizeCalled) {
                gameGA.sendDesignEvent('Game:ResizeTriggered');
                ga.basicGaEvent('game', 'resize_triggered');
                resizeCalled = true;
            }
        }

        const sideAdsShowUp = () => {
            const { clientWidth } = sideContainerRef.current;

            if (clientWidth >= 330 && window.innerHeight >= 620) {
                setSideBanner(true);
            } else {
                setSideBanner(false)
            }
        }

        if (sideContainerRef.current.clientWidth > 330 && window.innerHeight > 830) {
            setSideVideo(true)
        }

        sideAdsShowUp();

        const resizeHandler = () => {
            sideAdsShowUp();
            setIsResized();
        }

        window.addEventListener("resize", resizeHandler);

        return () => window.removeEventListener("resize", resizeHandler);
    }, [])

    return [sideBanner, sideVideo];
}
