import React from 'react';
import logo from '../../../../assets/footer-logo.svg';
import upButton from "../../../../assets/up-button.svg";
import LazyImg from "App/React/_UI/LazyImg";
import './styles.scss';

const Footer = ({showTermsUse}) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <footer className={"footer"}>
            <div className={"wrapper"}>
                <img
                    src={upButton} onClick={() => scrollToTop()}
                    className={"scroll-button"}
                    alt="up button"
                    loading="lazy"
                />
                <div className={"about-us"}>
                    <h3>About us</h3>
                    <p>We are a new gaming studio based in London, UK. Our mission is to create free and fun games for
                        the casual-gaming community.</p>
                    <p className={'fun'}>Have fun!</p>
                </div>
                <div className={"logo"}>
                    <LazyImg
                        url={logo}
                        width={229}
                        height={59}
                        alt="snake game footer logo"
                    />
                </div>
                <div className={"terms-use"}>
                    <p className={"apply"}>TERMS AND CONDITIONS APPLY</p>
                    <p className={"links"}>
                        <a
                            data-ssr={'terms'}
                            href={'terms-of-use.html'}
                            onClick={(e) => showTermsUse(e, 'terms')}>
                            Terms of Use
                        </a>
                        &nbsp;|&nbsp;
                        <a
                            data-ssr={'privacy'}
                            href={'privacy-policy.html'}
                            onClick={(e) => showTermsUse(e, 'privacy')}>
                            Privacy Policy
                        </a>
                    </p>
                </div>
            </div>
            <div className={"copyright"}>
                <p>
                    ©2023 Snake Color Brake. All Rights Reserved.
                </p>
            </div>
        </footer>
    )
};

export default Footer;
