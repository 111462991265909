import React, { useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { isMobile } from 'react-device-detect';
import { useAdService } from "App/Services/AdService";
import { handleCacheControl } from "App/helpers";
import {
    useSetGameCanvasDimensions,
    useGameFocus,
    useLoadingTime,
    useColorChange,
    useSendAnalytics,
    useSendAnalyticsError,
    useSendProgressionAnalyticsEvent,
    useLevelEnded,
    useRewardStarted,
    useGameSession,
} from "./hooks";
import BubbleLoader from "App/React/_UI/BubbleLoader";
import AdinplayBanners from "../AdinplayBanners";
import VliBanners from "../VliBanners";
import "./style.scss";

const Game = ({ gameAnalytics, gaAnalytics, gameConfig, orientation, adProvider }) => {
    const [sideBannerAd, setSideBannerAd] = useState(false);
    const [sideVideo, setSideVideo] = useState(false);
    const [startPreroll, setStartPreroll] = useState(false);
    const [isPrerollStartSent, setIsPrerollStartSent] = useState(false);
    const canvasRef = useRef(null);
    const sideContainerRef = useRef(null);
    const refBannerRight = useRef(null);
    const refBannerLeft = useRef(null);
    const refSideVideo = useRef(null);

    const { canvasWidth, canvasHeight} = useSetGameCanvasDimensions();

    useEffect(() => {
        let resizeCalled = false;

        const setIsResized = () => {
            if (!resizeCalled) {
                gameAnalytics.sendDesignEvent('Game:ResizeTriggered');
                gaAnalytics.basicGaEvent('game', 'resize_triggered');
                resizeCalled = true;
            }
        }

        const sideAdsShowUp = () => {
            const { clientWidth, clientHeight } = sideContainerRef.current;

            if (clientWidth >= 330 && clientHeight >= 620) {
                setSideBannerAd(true);
            } else {
                setSideBannerAd(false)
            }
        }

        if (sideContainerRef.current.clientWidth > 330 && sideContainerRef.current.clientHeight > 830) {
            setSideVideo(true)
        }

        sideAdsShowUp();

        const resizeHandler = () => {
            sideAdsShowUp();
            setIsResized();
        }

        window.addEventListener("resize", resizeHandler);

        return ()=> window.removeEventListener("resize", resizeHandler);
    }, [])

    useGameFocus(canvasRef);

    const { adProvInit, updateAd, showSideVideo, prerollCount: prerollEnd, consent, rewardReceived, setRewardReceived } = useAdService();
    const {
        loadingProgression,
        isLoaded,
        sendMessage,
        addEventListener,
        removeEventListener,
        unityProvider,
    } = useUnityContext({
        loaderUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.loader.js`,
        frameworkUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.framework.js.unityweb`,
        dataUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.data.unityweb`,
        codeUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.wasm.unityweb`,
        productName: "Snake Color Brake",
        companyName: "Reyo Media Cyprus",
        productVersion: gameConfig.buildVersion,
        cacheControl: handleCacheControl,
    });

    useLoadingTime(isLoaded, gameAnalytics, gaAnalytics);
    useGameSession(gameAnalytics, gaAnalytics, addEventListener, removeEventListener);
    useSendAnalytics(gameAnalytics, gaAnalytics, addEventListener, removeEventListener);
    useSendProgressionAnalyticsEvent(gameAnalytics, gaAnalytics, addEventListener, removeEventListener);
    useSendAnalyticsError(gameAnalytics, gaAnalytics, addEventListener, removeEventListener);
    // todo: remove useLevelEnded hook, no use for it
    useLevelEnded(addEventListener, removeEventListener);
    useRewardStarted(addEventListener, removeEventListener);

    const gameBgColor = useColorChange(addEventListener, removeEventListener);

    useEffect(() => {
        if (sideBannerAd && isLoaded && adProvInit ) {
            updateAd(refBannerLeft.current);
            updateAd(refBannerRight.current);
        }
    }, [isLoaded, adProvInit, sideBannerAd]);

    useEffect(() => {
        if (sideVideo && isLoaded && adProvInit) {
            showSideVideo(refSideVideo.current);
        }
    }, [isLoaded, adProvInit, sideVideo]);

    useEffect(() => {
        if (orientation && isMobile) {
            if (orientation.startsWith("portrait")) {
                sendMessage("WebApi", "ScreenOrientationChangeTo", "portrait");
            } else {
                sendMessage("WebApi", "ScreenOrientationChangeTo", "landscape");
            }
        }
    }, [orientation])

    useEffect(() => {
        if ((!isPrerollStartSent && startPreroll)) {
            setIsPrerollStartSent(true);
            sendMessage("WebApi", "BlockStartGame");
        }
    } ,[startPreroll])

    useEffect(() => {
        prerollEnd > 0 && startPreroll && setStartPreroll(false);

        if (prerollEnd > 0)  {
            sendMessage("WebApi", "UnblockStartGame");
            setIsPrerollStartSent(false);

            if (canvasRef.current) {
                canvasRef.current.focus();
                canvasRef.current.click();
            }
        }
    }, [prerollEnd])

    useEffect(() => {
        if (rewardReceived) {
            sendMessage("WebApi", "GrantReward", gameConfig.forcedReload);
            setRewardReceived(false);
        }
    }, [rewardReceived])

    useEffect(() => {
        // todo: change sendMessage method names to BlockGame & UnblockGame
        isLoaded && consent && sendMessage("WebApi", "PrerollStart");
        isLoaded && !consent && sendMessage("WebApi", "PrerollCompleted");
    }, [consent, isLoaded])

    return (
        <>
            <div
                className={"game-container"}
                style={{
                    background: gameBgColor,
                    height: isMobile ? canvasHeight : '100vh'
                }}
            >
                { !isLoaded &&
                    <BubbleLoader
                        backgroundColor={'#440494'}
                        loaderColor={'#FFFFFF'}
                        loadingProgression={loadingProgression}
                    />
                }
                <div ref={sideContainerRef} className={"side-container"}>
                    { sideBannerAd &&
                        <div ref={refBannerLeft} data-ga-id="snakecolorbrake_vli_300x600_left">
                            <div className="adsbyvli" data-ad-slot="pw_18501" />
                        </div>
                    }
                </div>
                <Unity
                    ref={canvasRef}
                    unityProvider={unityProvider}
                    style={{ width: canvasWidth, height: canvasHeight }}
                    devicePixelRatio={window.devicePixelRatio}
                />
                {/*only desktop vli banner*/}
                <div ref={sideContainerRef} className={"side-container"}>
                    { sideBannerAd &&
                        <div ref={refBannerRight} data-ga-id="snakecolorbrake_vli_300x600_right">
                            <div className="adsbyvli" data-ad-slot="pw_17217" />
                        </div>
                    }
                    { sideVideo &&
                        <div ref={refSideVideo} data-ga-id="snakecolorbrake_vli_video-banner">
                            <div className="adsbyvli" data-ad-slot="pw_18025"></div>
                        </div>
                    }
                </div>
                { adProvider === 'adinplay' &&
                    <AdinplayBanners
                        setStartPreroll={setStartPreroll}
                        removeEventListener={removeEventListener}
                        addEventListener={addEventListener}
                    />
                }
                { adProvider === 'vli' &&
                    <VliBanners
                        setStartPreroll={setStartPreroll}
                        removeEventListener={removeEventListener}
                        addEventListener={addEventListener}
                    />
                }
            </div>
        </>
    );
}

export default Game;
