import React from "react";
import "./styles.scss";

const BubbleLoader = ({ backgroundColor, loaderColor, loadingProgression = 0 }) => {
    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <div className="loading-overlay" style={{ backgroundColor: backgroundColor }}>
            {
                loadingProgression && loadingProgression !== 0 ?
                    <p className={"loading-percentage"} style={{ color: loaderColor }}>
                        {loadingPercentage}%
                    </p> : null
            }
            <div className="spinner">
                <div className="bubble-1" style={{ backgroundColor: loaderColor }} />
                <div className="bubble-2" style={{ backgroundColor: loaderColor }} />
            </div>
        </div>
    )
};

export default BubbleLoader;
