import config from "../config";

/**
 * check if more than N minutes pass from lass call
 *
 * @param prevTime - Date(required)
 * @param minutes - number(required)
 *
 * @return boolean
 */
export const hasElapsedMoreThanNMinutes = (prevTime, minutes) => {
    if (!prevTime) return false;

    const millisecondsDif = Math.abs(new Date() - prevTime);
    const minutesDif = millisecondsDif / (1000 * 60);

    return minutesDif > minutes;
}

/**
 * Checks whether an ad preroll should be shown based on an ad interval value
 *
 * @param curLevel - number(required)
 * @param showInterval - number(required)
 * @param showStartAttempt - number(required)
 *
 * @return boolean
 */
export const shouldDisplayPreroll = (curLevel, showInterval, showStartAttempt) => {
    if (showStartAttempt > curLevel) {
        return false;
    }

    if (showInterval === 0 && curLevel >= showStartAttempt) {
        return true;
    }

    const arr = [];

    for (let i = showStartAttempt; i <= curLevel; i += showInterval + 1) {
        arr.push(i)
    }

    return arr.includes(curLevel);
};

export const setBodyOverflow = ({ overflow }) => {
    document.documentElement.style.setProperty('--overflow', overflow);
}

/**
 * Gathers game default config values in to object and set them in web local storage
 *
 * @param config - obj (from LP config.js file)
 *
 * return {}
 */
export const getGameDefaults = (config) => {
    const { rid, buildVersion, buildPath, adProvider } = config;
    const build = localStorage.getItem('buildVersion') || buildVersion;

    localStorage.setItem('rid', rid);
    localStorage.setItem('buildVersion', buildVersion);

    return {
        adProvider: adProvider,
        gameConfig: {
            buildVersion: build,
            buildUrl: `${buildPath}${build}/`,
            forcedReload: 'false',
        },
        preroll: {
            showInterval: 1,
            showStartAttempt: 2,
        },
        pushPrompt: {
            total: 0,
        }
    }
}
