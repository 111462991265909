import React, {useRef} from "react";
import play from "../../../../assets/play.svg";
import snake6 from "../../../../assets/snake6.svg";
import aboutSnake from "../../../../assets/video-game.mp4"
import './styles.scss';

const About = ({onBtnClick}) => {
    const videoRef = useRef(null);

    const handleVideoEnded = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    return (
        <section className={"container section-about"}>
            <div className={"wrapper about"}>
                <div className={"animation-wrapper"}>
                    <video
                        ref={videoRef}
                        className={"animation"}
                        width="650"
                        height="1406"
                        autoPlay
                        muted
                        onEnded={handleVideoEnded}
                    >
                        <source src={aboutSnake} type="video/mp4"/>
                    </video>
                </div>
                <div className={"about-info"}>
                    <h2>About the
                        <span className={"highlighted"}> game</span>
                    </h2>
                    <div className={"description"}>
                        <p className={"body2"}>A fun, fast-paced online game, you control a snake of balls as it moves
                            across the screen, destroying blocks and growing in size the more balls you swipe.</p>
                        <br/>
                        <p className={"body2"}>The better you are, the bigger the snake grows and the harder blocks you
                            can destroy with your power-ups. Sound simple? It’s not. See if you’ve got the reflexes it
                            takes to master Snake Color Brake.</p>
                    </div>
                    <div className='about-button'>
                        <button onClick={() => onBtnClick()} className={"play-button"}>
                            Play For Free <img src={play} alt="play" className={"button-emoji"} loading="lazy"/>
                        </button>
                    </div>
                </div>
            </div>

            <div className={"about-snake"}>
                <img src={snake6} width={211} height={137} alt="snake" loading="lazy"/>
            </div>
        </section>
    )
}

export default About;