import React from 'react';
import toast from "react-hot-toast";
import exclamationIcon from "./assets/exclamation-mark.svg";

export const notify = (message) => {
    toast(
        <div style={{ display: "flex" }}>
            <img
                style={{ maxWidth: '30px', marginRight: '10px' }}
                src={exclamationIcon}
                alt="warting"
            />
            <p>{message}</p>
        </div>
    );
}
