import React from 'react';
import '../styles.scss';

const Card = ({img, text, description, alt}) => {
    const firstWord = text.split(' ')[0];
    const restOfWords = text.split(' ').slice(1);

    return (
        <div className={"card"}>
            <div className={"card_img"}>
                <img
                    src={img}
                    width={426}
                    height={429}
                    alt={alt}
                />
            </div>
            <div className={"card_content"}>
                <h3 className={"card_title"}>
                    <span className={"highlighted"}>{firstWord}</span> {restOfWords.join(' ')}
                </h3>
                <p className={"card_text"}>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default Card;
