import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/browser";
import OneSignal from "react-onesignal";
import { browserName, fullBrowserVersion, osName, osVersion } from "react-device-detect";
import { oneSignalConfig } from "../../config";
import { consoleInfoLog } from "App/Helpers/consoleInfoLog";
import { isProd } from "App/Helpers/enviroments";
import checkAdBlockers from "App/Helpers/checkAdBlockers";
import BubbleLoader from "App/React/_UI/BubbleLoader";
import thumbUp from '../../assets/emoji-like.svg';
import freeIcon from '../../assets/free-icon.svg';
import registrationIcon from '../../assets/registration-icon.svg';
import permissionIcon from '../../assets/permission-icon.svg';
import logo from '../../assets/logo.svg';
import snakeX from '../../assets/snake6.svg';
import './styles.scss';

const PushPhase = ({ sharpstarService, params, gameGA, ga, setPhase, updateState }) => {
    const [inited, setInited] = useState(false);

    useEffect(() => {
        if (Notification && Notification.permission === "default") {
            initOneSignal();

            gameGA.sendDesignEvent('Push:PageShow');
            ga.basicGaEvent('push', 'page_show');
        } else if (Notification && Notification.permission === "granted") {
            // we suppose that user has OneSignal initialized already or user permitted web push for all browsers
            gameGA.sendDesignEvent('Push:GrantedByBrowser');
            ga.basicGaEvent('push', 'granted_by_browser');
            setPhase('game');
        } else if (Notification && Notification.permission === "denied") {
            // we suppose that user denied OneSignal web push or user has forbidden web push for all browsers
            gameGA.sendDesignEvent('Push:DeniedByBrowser');
            ga.basicGaEvent('push', 'denied_by_browser');
            setPhase('game');
        } else {
            Sentry.captureException(new Error('No Web Push Notification.permission'));
            setPhase('game');
        }
    }, [])

    const initOneSignal = () => {
        OneSignal
            .init(oneSignalConfig)
            .then(() => {
                consoleInfoLog('Inited OneSignal', '#f3aa4e', '#094205');

                OneSignal.on('subscriptionChange', (subscribed) => {
                    consoleInfoLog(`OneSignal on subscriptionChange event. Subscribed: ${subscribed}`);

                    if (subscribed) {
                        OneSignal.getUserId()
                            .then(async (playerId) => {
                                consoleInfoLog(`OneSignal from getUserId method. Player id: ${playerId}`);

                                try {
                                    const offer = params.offerId || localStorage.getItem('offerId');

                                    await sharpstarService.createPushLead(playerId, offer, params.rid);
                                } catch (e) {
                                    Sentry.captureException(e);
                                }
                            });

                        sharpstarService.addOneSignalLeadCountTest(
                            `${osName} ${osVersion}`,
                            `${browserName} ${fullBrowserVersion}`,
                            isProd() ? params.refid3 : `${params.refid2}_${params.refid3}`
                        );

                        Sentry.withScope(function (scope) {
                            scope.setLevel("info");
                            Sentry.captureException(new Error('User allowed web push on native prompt'));
                        });
                    }
                })

                OneSignal.on('notificationPermissionChange', (permissionChange) => {
                    const permission = permissionChange.to;
                    consoleInfoLog(`OneSignal on notificationPermissionChange event. Permission: ${permission}`);

                    gameGA.sendDesignEvent(`Push:${permission.charAt(0).toUpperCase() + permission.slice(1)}`)
                    ga.basicGaEvent('push', permission)
                    localStorage.setItem('push-allowed', permission);
                });

                OneSignal.on('permissionPromptDisplay', function () {
                    gameGA.sendDesignEvent('Push:NPopupShow');
                    ga.basicGaEvent('push', 'native_popup_show');
                });

                setInited(true);
            })
            .catch((e) => {
                consoleInfoLog('OneSignal init failed', '#f3aa4e', '#094205');
                Sentry.captureException(e);
            })
    }

    const handleBtnClick = (btnName) => {
        gameGA.sendDesignEvent(`Push:${btnName === 'allow' ? 'Allow' : 'Continue'}`);
        ga.basicGaEvent('push', `${btnName === 'allow' ? 'allow' : 'continue'}`);

        checkAdBlockers((adsBlocked) => {
            if (adsBlocked) {
                updateState({
                    showAdBlockerModal: true,
                    adBlocker: adsBlocked,
                });
            } else {
                setPhase('game');

                if (btnName === 'allow') {
                    try {
                        OneSignal.showNativePrompt();
                    } catch (e) {
                        Sentry.captureException(new TypeError("OneSignal doesn't exist or isn't supported"));
                    }
                }
            }
        });
    }

    if (!inited) return <BubbleLoader backgroundColor={'#440494'} loaderColor={'#FFFFFF'}/>;

    return (
        <div className={"modal-push_overlay"}>
            <div className={"modal-push_header"}>
                <img src={logo} width={287} height={102} alt="snake game logo" className={"modal-push_logo"} />
            </div>
            <div className={"modal-push_content"}>
                <div className={"modal-push_title"}>Click Allow to Get Freebies and Updates!</div>
                <div className={"modal-push_row"}>
                    <div className={"modal-push_column"}>
                        <img src={freeIcon} width={47} height={47} alt="free icon" className={"modal-push_icon"}/>
                        <div className={"modal-push_span"}>100% Free - No Credit Card Required</div>
                    </div>
                    <div className={"modal-push_column"}>
                        <img src={registrationIcon} width={47} height={47} alt="snake game registration " className={"modal-push_icon"}/>
                        <div className={"modal-push_span"}>No Registration Needed</div>
                    </div>
                    <div className={"modal-push_column"}>
                        <img src={permissionIcon} width={47} height={47} alt="snake game permission " className={"modal-push_icon"}/>
                        <div className={"modal-push_span"}>No App Permissions</div>
                    </div>
                </div>
                <div>
                    <button onClick={() => handleBtnClick('allow')} className={"play-button"}>
                        Allow <img src={thumbUp} alt="thumb up emoji" className={"modal-push_button-emoji"}/>
                    </button>
                </div>
                <div onClick={() => handleBtnClick('continue')} className={"modal-push_button-continue"}>
                    Continue to game
                </div>
            </div>
            <div className={"modal-push_footer_wrapper"}>
                <div className={"modal-push_footer"}></div>
                <img src={snakeX} width={211} height={137} alt="snakey game" className={"modal-push_snake"} />
            </div>
        </div>
    )
};

export default PushPhase;
